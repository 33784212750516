<template>
  <section class="conOfFooter">
    <section class="sectionTwo">
      <v-container>
        <v-row class="align-center">
          <v-col md="12" cols="12" class="py-1">
            <div class="conOfFooterCopyright">
              <div class="footerCopyright text-center">©Copyright, 2021</div>

              <div class="btnContainer text-center">
                <v-btn text>
                  <v-icon color="#fff"> mdi-linkedin </v-icon>
                </v-btn>
                <v-btn text>
                  <v-icon color="#fff"> mdi-google </v-icon>
                </v-btn>
                <v-btn text>
                  <v-icon color="#fff"> mdi-twitter </v-icon>
                </v-btn>
                <v-btn text>
                  <v-icon color="#fff"> mdi-facebook </v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>
<script>
export default {
  name: "footerCopyRight",
  data: () => ({}),
};
</script>
