<template>
  <v-app class="v-app-Style fill-height">
    <main class="mainStyle">
      <Menu />
      <router-view></router-view>
    </main>
    <div class="conOfAllFooterSection">
      <FooterMessage />
      <FooterCopyRight />
    </div>
  </v-app>
</template>

<script>
import Menu from "@/modules/shared/components/menu-bar";

import FooterCopyRight from "@/modules/shared/components/layout/FooterCopyRight";
import FooterMessage from "@/modules/shared/components/layout/FooterMessage";
export default {
  components: {
    FooterMessage,
    FooterCopyRight,
    Menu,
  },
  computed: {},
  watch: {},
};
</script>
<style>
.mainStyle {
  height: 100%;
}
</style>
