<template>
  <div class="fill-height">
    <MessageService />

    <AppLayout />
  </div>
</template>

<script>
import AppLayout from "@/modules/shared/components/app-layout";
import MessageService from "@/modules/shared/components/message-service";
export default {
  components: {
    AppLayout,
    MessageService,
  },
};
</script>
<style>
.theme--dark.v-overlay {
  z-index: 1 !important;
}
</style>
