<template>
  <section class="conOfFooter" v-if="!appearFooter">
    <section class="sectionOne">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col md="4" sm="4" cols="12">
                <div class="conEachFooterSec">
                  <div class="headerSec">QUICK LINKS</div>
                  <div class="d-flex">
                    <div class="conOFLinks">
                      <a
                        target="_blank"
                        class="eachLinkFooter"
                        onclick="window.open(this.href,'_blank');return false;"
                        href="https://arabdoctors.co/about-us/ "
                      >
                        About us
                      </a>
                      <a
                        target="_blank"
                        class="eachLinkFooter"
                        onclick="window.open(this.href,'_blank');return false;"
                        href="https://arabdoctors.co/our-values/ 
"
                      >
                        Our Values
                      </a>
                      <a
                        target="_blank"
                        class="eachLinkFooter"
                        onclick="window.open(this.href,'_blank');return false;"
                        href="https://arabdoctors.co/our-products/  
"
                      >
                        Our Products
                      </a>
                    </div>
                    <div class="conOFLinks">
                      <a
                        target="_blank"
                        class="eachLinkFooter"
                        onclick="window.open(this.href,'_blank');return false;"
                        href="https://arabdoctors.co/contact-us/  
"
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col md="4" sm="4" cols="12" class="contacts-sec">
                <div class="conEachFooterSec">
                  <div class="headerSec">Products</div>
                  <div class="d-flex">
                    <div class="conOFLinks">
                      <v-btn
                        text
                        tile
                        :ripple="false"
                        class="eachLinkFooter"
                        :to="{ name: 'about' }"
                      >
                        Move On Cream
                      </v-btn>
                      <v-btn
                        text
                        tile
                        :ripple="false"
                        class="eachLinkFooter"
                        :to="{ name: 'about' }"
                      >
                        Move On Spray
                      </v-btn>
                      <v-btn
                        text
                        tile
                        :ripple="false"
                        class="eachLinkFooter"
                        :to="{ name: 'about' }"
                      >
                        Move On Freeze Gel
                      </v-btn>
                    </div>
                    <div class="conOFLinks">
                      <v-btn
                        text
                        tile
                        :ripple="false"
                        class="eachLinkFooter"
                        :to="{ name: 'about' }"
                      >
                        Move On Freeze Spray
                      </v-btn>
                      <v-btn
                        text
                        tile
                        :ripple="false"
                        class="eachLinkFooter"
                        :to="{ name: 'about' }"
                      >
                        Move On Freeze Patch
                      </v-btn>
                      <v-btn
                        text
                        tile
                        :ripple="false"
                        class="eachLinkFooter"
                        :to="{ name: 'about' }"
                      >
                        Move On Hot Patch
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col md="4" sm="4" cols="12" class="contacts-sec">
                <div class="conEachFooterSec">
                  <div class="headerSec">Contacts</div>
                  <div class="conOFLinks">
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                      :to="{ name: '' }"
                    >
                      <v-icon class="iconTxt"> mdi-google-maps </v-icon>

                      jeddah-Saudi Arabia
                    </v-btn>
                    <a class="eachLinkFooter" href="tel:+966 12 692 9404">
                      <v-icon class="iconTxt"> mdi-phone </v-icon>

                      +966 12 692 9404
                    </a>
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                      :to="{ name: '' }"
                    >
                      <v-icon class="iconTxt"> mdi-email-outline </v-icon>

                      info@arabdoctors.co
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>
<script>
export default {
  name: "footerMessage",
  components: {},
  data: () => ({
    appearFooter: false,
  }),
  methods: {
    hideFooter() {
      this.appearFooter = true;
      this.$store.commit("setFooterExist", true);
    },
  },
};
</script>
